import React, { Suspense, useEffect } from 'react'
import { ProtectedRouteAdmin, ProtectedRouteBusiness } from 'routes'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { deleteExpiredData } from 'utils/clearLocalStorage'

const PostModifyContainer = React.lazy(
  async () => await import('pages/business/posts/modify/postModifyContainer')
)
const ResetPasswordEmailProviderContainer = React.lazy(
  async () =>
    await import(
      'components/resetPassword/emailProvider/resetPasswordEmailProviderContainer'
    )
)
const ProvideNewPasswordContainer = React.lazy(
  async () =>
    await import(
      'components/resetPassword/provideNewPassword/provideNewPasswordContainer'
    )
)
const Partnership = React.lazy(
  async () => await import('pages/marketplace/partnership')
)
const PostsContainer = React.lazy(
  async () => await import('pages/business/posts/list/postsContainer')
)
const RegistrationStepperContainer = React.lazy(
  async () =>
    await import(
      'pages/business/posts/registration/registrationStepperContainer'
    )
)
const AccountSettingsView = React.lazy(
  async () => await import('pages/business/account')
)
const Marketplace = React.lazy(async () => await import('pages/marketplace'))

const ShopContainer = React.lazy(
  async () => await import('pages/marketplace/shop/shopContainer')
)
const Results = React.lazy(
  async () => await import('pages/marketplace/results')
)
const ProductContainer = React.lazy(
  async () => await import('pages/marketplace/product/productContainer')
)
const HomePageContent = React.lazy(
  async () => await import('pages/marketplace/home')
)
const PageNotFound404 = React.lazy(async () => await import('components/404'))

function App() {
  useEffect(() => {
    // Call the deleteExpiredData function once every 24 hours (86400000 milliseconds)
    setInterval(deleteExpiredData, 86400000)
  }, [])

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Marketplace />}>
            <Route index element={<HomePageContent />} />
            <Route path="results" element={<Results />} />
            <Route
              path="product/:postID/:productTitle"
              element={<ProductContainer />}
            />
            {
              //<Route path="shop/:businessID" element={<ShopContainer />} />
            }
            <Route
              path="reset"
              element={<ResetPasswordEmailProviderContainer />}
            />
            <Route
              path="reset/:resetPasswordToken"
              element={<ProvideNewPasswordContainer />}
            />
            <Route path="partnership" element={<Partnership />} />
          </Route>
          <Route
            path="/business/:businessID"
            element={<ProtectedRouteBusiness />}>
            <Route index element={<PostsContainer />} />

            <Route
              path="registration"
              element={<RegistrationStepperContainer />}
            />
            <Route path="account" element={<AccountSettingsView />} />
            <Route path="modify/:postID" element={<PostModifyContainer />} />
          </Route>
          <Route path="/admin" element={<ProtectedRouteAdmin />} />
          <Route path="*" element={<PageNotFound404 />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
