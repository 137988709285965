import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userIsLogedIn: false,
  userIsAdmin: false,
  token: null,
  user: {}
}

export const loginStatusSlice = createSlice({
  name: 'loginStatus',
  initialState,
  reducers: {
    // LOGIN STATUS
    LOG_IN: (state, action) => {
      state.userIsLogedIn = action.payload
    },
    // LOGIN STATUS
    LOG_OUT: (state) => {
      state.userIsLogedIn = false
      state.userIsAdmin = null
      state.user = {}
      state.token = null
    },
    // USER ROLE
    USER_IS_ADMIN: (state, action) => {
      state.userIsAdmin = action.payload
    },
    // AUTH TOKEN
    SET_TOKEN: (state, action) => {
      state.token = action.payload
    },
    // USER DATA
    SET_USER: (state, action) => {
      state.user = action.payload
    }
  }
})

export const { LOG_IN, SET_TOKEN, USER_IS_ADMIN, SET_USER, LOG_OUT } =
  loginStatusSlice.actions

export default loginStatusSlice.reducer
