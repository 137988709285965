import { useEffect, lazy } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Business = lazy(async () => await import('pages/business'))
const Admin = lazy(async () => await import('pages/administration'))

export function ProtectedRouteAdmin() {
  const navigate = useNavigate()
  const { userIsLogedIn, userIsAdmin } = useSelector(
    ({ loginStatus }) => loginStatus
  )

  useEffect(() => {
    if (!userIsLogedIn || !userIsAdmin) navigate('/')
  }, [userIsLogedIn, userIsAdmin])

  return !userIsLogedIn || !userIsAdmin ? null : <Admin />
}

export function ProtectedRouteBusiness() {
  const navigate = useNavigate()
  const { userIsLogedIn } = useSelector(({ loginStatus }) => loginStatus)

  useEffect(() => {
    if (!userIsLogedIn) navigate('/')
  }, [userIsLogedIn])

  return !userIsLogedIn ? null : <Business />
}
