import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  openMoreCriteresMenu: false,
  searchValues: {
    title: '',
    city: '',
    category: '',
    brand: '',
    model: '',
    generation: '',
    pageIndex: 1
  }
}

export const searchEngineSlice = createSlice({
  name: 'searchEngineInputs',
  initialState,
  reducers: {
    SAVE_SEARCH_INPUTS: (state, action) => {
      state.searchValues = action.payload
    },
    CLEAR_SEARCH_INPUTS: (state) => {
      state.searchValues = {
        title: '',
        city: '',
        category: '',
        brand: '',
        model: '',
        generation: '',
        pageIndex: 1
      }
    },
    SET_PAGE_INDEX: (state, action) => {
      state.searchValues = {
        ...state.searchValues,
        pageIndex: action.payload
      }
    },
    OPEN_CRITERES_MENU: (state) => {
      state.openMoreCriteresMenu = !state.openMoreCriteresMenu
    }
  }
})

export const {
  SAVE_SEARCH_INPUTS,
  CLEAR_SEARCH_INPUTS,
  SET_PAGE_INDEX,
  OPEN_CRITERES_MENU
} = searchEngineSlice.actions

export default searchEngineSlice.reducer
