import { createSlice } from '@reduxjs/toolkit'

export const postRegistrationSlice = createSlice({
  name: 'postRegistration',
  initialState: {},
  reducers: {
    // SAVE POST DATA
    SAVE_POST_INPUT: (state, action) => {
      const { fieldName, value } = action.payload
      return {
        ...state,
        [fieldName]: value
      }
    },
    // RESET POST DATA TO INITIAL STATE
    RESET_POST_DATA_TO_INITIAL_STATE: (state, action) => {
      return {} // Return the initial state object to reset the data
    }
  }
})

export const { SAVE_POST_INPUT, RESET_POST_DATA_TO_INITIAL_STATE } =
  postRegistrationSlice.actions

export default postRegistrationSlice.reducer
