import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { loginStatusSlice } from './loginStatusSlice'
import { searchEngineSlice } from './searchEngineSlice'
import { postRegistrationSlice } from './postRegistrationSlice'

const rootReducer = combineReducers({
  loginStatus: loginStatusSlice.reducer,
  searchEngineInputs: searchEngineSlice.reducer,
  postRegistrationData: postRegistrationSlice.reducer
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: []
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
})

export const persistor = persistStore(store)
